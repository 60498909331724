<template>
    <el-row :gutter="20" class="speed">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="speed-left">
                <div class="car-title">
                    <h4>Скорость</h4>
                </div>
                <div class="d-flex justify-center">
                    <!-- <el-progress width="200" :stroke-width="18" type="dashboard"  :percentage="percentage" :color="colors"></el-progress> -->
                    <v-chart
                        class="e-chart widht-e-chart"
                        :option="option"
                        autoresize
                    />
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
import * as echarts from "echarts/core";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import VChart, { THEME_KEY } from "vue-echarts";
echarts.use([GaugeChart, CanvasRenderer]);
export default {
    components: {
        VChart,
    },
    provide() {
        return {
            [THEME_KEY]: true ? "dark" : "light",
        };
    },
    data() {
        return {
            option: {
                backgroundColor: "transparent",
                title: {
                    text: "World Population",
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow",
                    },
                },
                legend: {},
                grid: {
                    left: "1%",
                    right: "1%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: ["Brazil", "Indonesia", "USA"],
                },
                yAxis: {
                    type: "value",
                    boundaryGap: [0, 0.01],
                },
                series: [
                    {
                        name: "2011",
                        type: "bar",
                        data: [18203, 131744, 630230],
                    },
                    {
                        name: "2012",
                        type: "bar",
                        data: [19325, 134141, 681807],
                    },
                ],
            },
        };
    },
};
</script>
<style lang="scss" scoped>
</style>
