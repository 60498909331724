<template>
  <div>
    <div class="car-title d-flex child-center f-between">
        <h4>Приоритетные</h4>
        <el-select style="width: 100px;" size="mini" v-model="value" placeholder="Select">
            <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
    </div>
    <ul class="overdue-ul">
        <li>
            <!-- <div class="date-overdue">3 апр</div> -->
            <div class="date-number prior1">1</div>
            <div class="date-text">Составить тех. задание</div>
            <a class="ava thickbox" >
                <span class="av_sm av_let9 ru-0">DU</span>
            </a>
        </li>
        <li>
            <!-- <div class="date-overdue">3 апр</div> -->
            <div class="date-number prior1">1</div>
            <div class="date-text">Составить тех. задание</div>
            <a class="ava thickbox" >
                <span class="av_sm av_let9 ru-0">DU</span>
            </a>
        </li>
        <li>
            <!-- <div class="date-overdue">3 апр</div> -->
            <div class="date-number prior1">1</div>
            <div class="date-text">Составить тех. задание</div>
            <a class="ava thickbox" >
                <span class="av_sm av_let9 ru-0">DU</span>
            </a>
        </li>
        <li>
            <!-- <div class="date-overdue">3 апр</div> -->
            <div class="date-number prior1">1</div>
            <div class="date-text">Составить тех. задание</div>
            <a class="ava thickbox" >
                <span class="av_sm av_let9 ru-0">DU</span>
            </a>
        </li>
        <li>
            <!-- <div class="date-overdue">3 апр</div> -->
            <div class="date-number prior1">1</div>
            <div class="date-text">Составить тех. задание</div>
            <a class="ava thickbox" >
                <span class="av_sm av_let9 ru-0">DU</span>
            </a>
        </li>
        <li>
            <!-- <div class="date-overdue">3 апр</div> -->
            <div class="date-number prior1">1</div>
            <div class="date-text">Составить тех. задание</div>
            <a class="ava thickbox" >
                <span class="av_sm av_let9 ru-0">DU</span>
            </a>
        </li>
        <li>
            <!-- <div class="date-overdue">3 апр</div> -->
            <div class="date-number prior1">1</div>
            <div class="date-text">Составить тех. задание</div>
            <a class="ava thickbox" >
                <span class="av_sm av_let9 ru-0">DU</span>
            </a>
        </li>
        <li>
            <!-- <div class="date-overdue">3 апр</div> -->
            <div class="date-number prior1">1</div>
            <div class="date-text">Составить тех. задание</div>
            <a class="ava thickbox" >
                <span class="av_sm av_let9 ru-0">DU</span>
            </a>
        </li>
        <li>
            <!-- <div class="date-overdue">3 апр</div> -->
            <div class="date-number prior1">1</div>
            <div class="date-text">Составить тех. задание</div>
            <a class="ava thickbox" >
                <span class="av_sm av_let9 ru-0">DU</span>
            </a>
        </li>

    </ul>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        options: [{
          value: 'Option1',
          label: 'Все'
        }, {
          value: 'Option2',
          label: 'Подписка'
        }, {
          value: 'Option3',
          label: 'Мои'
        }],
        value:'Option1',
      }
    }
  }
</script>
