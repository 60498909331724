<template>
    <div style="margin: 20px" class="style-home">
        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                <el-card class="box-card mb-4 none-p h-min-150">
                    <Supervisor></Supervisor>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
                <el-card class="box-card mb-4 none-p h-min-150">
                    <Time></Time>
                </el-card>
            </el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <el-card class="box-card mb-4 none-p">
                    <Speed></Speed>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <el-card class="box-card mb-4 none-p">
                    <timeSroc></timeSroc>
                </el-card>
                <el-card class="box-card mb-4 none-p">
                    <ActivityChart></ActivityChart>
                </el-card>
            </el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <el-card class="box-card mb-4 none-p">
                    <ByStatus></ByStatus>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
                <el-card class="box-card mb-4 none-p">
                    <People></People>
                </el-card>
            </el-col>
        </el-row>

        <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                <el-card class="box-card mb-4 min-h-380 none-p">
                    <Overdue></Overdue>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                <el-card class="box-card mb-4 min-h-380 none-p">
                    <Priority></Priority>
                </el-card>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
                <el-card class="box-card mb-4 min-h-380 none-p">
                    <Events></Events>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Supervisor from "./components/supervisor";
import Time from "./components/time";
import Speed from "./components/speed";
import ActivityChart from "./components/activityChart";
import TimeSroc from "./components/timeSroc";
import People from "./components/people";
import ByStatus from "./components/byStatus";
import Overdue from "./components/overdue";
import Priority from "./components/priority";
import Events from "./components/events";

export default {
    name: "review",
	 props:{
		project_id: {
			default: null
		}
	 },
    components: {
        Supervisor,
        Time,
        Speed,
        ActivityChart,
        TimeSroc,
        Overdue,
        Events,
        Priority,
        People,
        ByStatus,
    },
	 computed:{
		...mapGetters({

		}),
	 },
	 created(){
		// console.log('model id in review', this.project_id);
	 },
    data() {
        return {};
    },
	 methods:{
		...mapActions({

		}),
	 },

};
</script>

<style>
.h-min-150 {
    min-height: 140px;
}
</style>
