<template>
	<div class=" projectPortfolio-style mt-5">
		<div class="p-0 p-my-card-header-left">
			<router-link :to="{ name: 'projectPortfolio' }" class="mr-5">
				<i class="fa-solid fa-arrow-left"></i>
			</router-link>
			<span>
				<a :to="{ name: 'overview' }"> {{ model ? model.name : '' }} </a>
			</span>
			<i class="fa-regular fa-star fa-solid" :class="[model.mark ? 'mark_enable' : '']"></i>
			<!-- <i class="fa-solid fa-gear"></i> -->
		</div>
		<el-tabs v-model="activeProjectTabs" class="project_new_tabs">
			<el-tab-pane v-if="permissions.some(per => per.slug == 'boards.index')" :label="$t('message.deals')" name="deal">
				<Deals v-if="activeProjectTabs == 'deal'" ref="deal" :project_id="model_id"></Deals>
			</el-tab-pane>
			<el-tab-pane v-if="permissions.some(per => per.slug == 'taskBoards.index')" :label="$t('message.tasks')" name="task">
				<Task v-if="activeProjectTabs == 'task'" ref="task" :project_id="model_id"></Task>
			</el-tab-pane>
			<el-tab-pane :label="$t('message.review')" name="review">
				<Review v-if="activeProjectTabs == 'review'" ref="review" :project_id="model_id"></Review>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapActions} from "vuex";
import Task from "@/views/tasks/index";
import Deals from "@/views/deals/index";
import Review from "../review/index";

export default {
    components: {
		Review,
		Task,
		Deals,
    },
    name: "project",
    data() {
        return {
            activeProjectTabs: "deal",
            model_id: null,
        };
    },
	mounted() {
		if(this.permissions.some(per => per.slug == 'boards.index')){
			this.activeProjectTabs = 'deal';
		}else if(this.permissions.some(per => per.slug == 'taskBoards.index')){
			this.activeProjectTabs = 'task';
		}
	},
    created(){
        this.model_id = this.$route.params.project_id;
        this.show(this.model_id);
    },
	watch: {
		'activeProjectTabs': {
			handler: function (activeName){
				if (activeName == 'task'){
					this.$nextTick(function() {
						if(this.$refs.task && _.isFunction(this.$refs.task.debouncedFetchData)){
							this.$refs.task.debouncedFetchData();
						}
             		});
					this.$store.commit('boards/EMPTY_LIST');
				}
				else if(activeName == 'deal'){
					this.$nextTick(function() {
						if(this.$refs.deal && _.isFunction(this.$refs.deal.debouncedGetList)){
							this.$refs.deal.debouncedGetList();
						}
             		});
					this.$store.commit('taskBoards/EMPTY_LIST');
				}
				else if (activeName == 'review') {
					this.$nextTick(function() {
						console.log('review', this.$refs.review);
						// this.$refs.review.debouncedFetchData();
             	});
				}

			},
			deep: true,
			immediate: true
		}
	},
    computed: {
		...mapGetters({
			model: "projects/model",
			permissions: "auth/permissions"
		}),
    },
    methods: {
        ...mapActions({
            show: "projects/show",
		  }),
    },
	beforeRouteLeave(to, from, next){
		this.$store.commit("taskBoards/EMPTY_LIST");
		this.$store.commit('projects/EMPTY_MODEL');
		this.$store.commit("boards/EMPTY_LIST");
		if (this.$refs['deal']) this.$refs['deal'].rows = [];
		next();
   }
};
</script>

<style>
.mark_enable{
	color: #ff8b00 !important;
}
</style>
