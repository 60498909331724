<template>
    <el-row :gutter="20" class="speed">
        {{ mode }}
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="speed-left">
                <div class="car-title">
                    <h4>Скорость</h4>
                </div>
                <div class="d-flex justify-center">
                    <!-- <el-progress width="200" :stroke-width="18" type="dashboard"  :percentage="percentage" :color="colors"></el-progress> -->
                    <v-chart class="e-chart" :option="option" autoresize />
                </div>
            </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div class="speed-right">
                <div class="car-title">
                    <h4>Открытые</h4>
                </div>
                <div class="d-flex justify-center">
                    <v-chart
                        class="e-chart widht-e-chart"
                        :option="option2"
                        autoresize
                    />
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
import { mapGetters } from "vuex";

import * as echarts from "echarts/core";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import VChart, { THEME_KEY } from "vue-echarts";
echarts.use([GaugeChart, CanvasRenderer]);
export default {
    components: {
        VChart,
    },
    provide() {
        return {
            [THEME_KEY]: !this.mode ? "dark" : "light",
        };
    },
    data() {
        return {
            option: {
                backgroundColor: "transparent",
                series: [
                    {
                        type: "gauge",
                        axisLine: {
                            lineStyle: {
                                width: 20,
                                color: [
                                    [0.3, "#fd666d"],
                                    [0.7, "#37a2da"],
                                    [1, "#67e0e3"],
                                ],
                            },
                        },
                        pointer: {
                            itemStyle: {
                                color: "inherit",
                            },
                        },
                        axisTick: {
                            distance: -30,
                            length: 8,
                            lineStyle: {
                                color: "#fff",
                                width: 2,
                            },
                        },
                        splitLine: {
                            distance: -30,
                            length: 30,
                            lineStyle: {
                                color: "#fff",
                                width: 4,
                            },
                        },
                        axisLabel: {
                            color: "inherit",
                            distance: 40,
                            fontSize: 10,
                        },
                        detail: {
                            valueAnimation: true,
                            formatter: "{value} km/h",
                            color: "inherit",
                        },
                        data: [
                            {
                                value: 70,
                            },
                        ],
                    },
                ],
            },

            option2: {
                backgroundColor: "transparent",
                tooltip: {
                    trigger: "item",
                },
                legend: {
                    top: "5%",
                    left: "left",
                    orient: "vertical",
                },
                series: [
                    {
                        name: "Access From",
                        type: "pie",
                        radius: ["50%", "70%"],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: "#fff",
                            borderWidth: 2,
                        },
                        label: {
                            show: false,
                            position: "center",
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 20,
                                fontWeight: "bold",
                            },
                        },
                        labelLine: {
                            show: false,
                        },
                        data: [
                            { value: 500, name: "Search Engine" },
                            { value: 500, name: "Direct" },
                            { value: 400, name: "Email" },
                        ],
                    },
                ],
            },
        };

    },
    computed: {
        ...mapGetters({
            mode: "MODE"
        })
    },
};
</script>
<style lang="scss" scoped>
</style>
