<template>
  <div class="time-progress">
    <div class="last">
        <b class="ok">{{percentage}} %</b>
        <div>времени прошло</div>
        <el-button size="mini"  class="mt-3">Описание</el-button>
    </div>
    <div class="tick tick_r flex-1">
        <div class="label left">
		    <b>Создан</b><br>
            <p>3 апреля</p>
        </div>
        <div class="label right">
			<b>Финиш</b><br>
            <p>3 июня</p>
        </div>
        <el-progress :text-inside="true" :color="customColors" :stroke-width="15" :percentage="percentage"></el-progress>

    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        percentage: 90,
        customColors: [
          {color: '#f56c6c', percentage: 100},
          {color: '#e6a23c', percentage: 80},
          {color: '#5cb87a', percentage: 60},
          {color: '#1989fa', percentage: 40},
          {color: '#6f7ad3', percentage: 20}
        ]
      };
    },
    methods: {

    }
  }
</script>
<style>
.time-progress{
    display: flex;
    padding: 10px 20px;
}
.last{
    width: 204px;
}
.last b{
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 5px;
}
.tick_r .label p {
    padding: 0;
    margin: 0;
}
.tick_r .label p,
.last div{
    color: #939697;
    font-size: 13px;

}
.flex-1{
    flex: 1;
}
.tick_r{
    position: relative;
    padding-top: 50px;
}

.tick_r .label.right{
    position: absolute;
    right: 0;
    top: 10px;
}
.tick_r .label.left{
    position: absolute;
    left: 0;
    top: 10px;
}
</style>
