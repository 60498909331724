<template>
    <el-row :gutter="20" class="speed">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <div class="speed-left">
                <div class="car-title">
                    <h4>Скорость</h4>
                </div>
                <div class="d-flex justify-center">
                    <!-- <el-progress width="200" :stroke-width="18" type="dashboard"  :percentage="percentage" :color="colors"></el-progress> -->
                    <v-chart
                        class="e-chart widht-e-chart"
                        :option="option"
                        autoresize
                    />
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
import * as echarts from "echarts/core";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import VChart, { THEME_KEY } from "vue-echarts";
echarts.use([GaugeChart, CanvasRenderer]);
export default {
    components: {
        VChart,
    },
    provide() {
        return {
            [THEME_KEY]: true ? "dark" : "light",
        };
    },
    data() {
        return {
            option: {
                backgroundColor: "transparent",
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        // Use axis to trigger tooltip
                        type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                },
                legend: {},
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        name: "Direct",
                        type: "bar",
                        stack: "total",
                        label: {
                            show: true,
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [320, 302, 301, 334, 390, 330, 320],
                    },
                    {
                        name: "Mail Ad",
                        type: "bar",
                        stack: "total",
                        label: {
                            show: true,
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [120, 132, 101, 134, 90, 230, 210],
                    },
                    {
                        name: "Affiliate Ad",
                        type: "bar",
                        stack: "total",
                        label: {
                            show: true,
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [220, 182, 191, 234, 290, 330, 310],
                    },
                    {
                        name: "Video Ad",
                        type: "bar",
                        stack: "total",
                        label: {
                            show: true,
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [150, 212, 201, 154, 190, 330, 410],
                    },
                    {
                        name: "Search Engine",
                        type: "bar",
                        stack: "total",
                        label: {
                            show: true,
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: [820, 832, 901, 934, 1290, 1330, 1320],
                    },
                ],
            },
        };
    },
};
</script>
